import React from 'react';
import ProductListPage from 'rev.sdk.js/Templates/ProductList';
import * as CustomBrowse from '../../Utils/CustomBrowse';
import withPageEntry from '../../withPageEntry';

function ProductList(props) {
  return <ProductListPage {...CustomBrowse.getExtraProductListProps(props)} />;
}

export default withPageEntry(ProductList);
