import React, {useMemo, useState} from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import Empty from '../../../../Components/Empty';
import ProductItem from './ProductItem';
import ArticleItem from './ArticleItem';

function ProductGrid(props) {
  const {products, onItemClick, prefixPath} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  if (products.length <= 0) {
    return <Empty />;
  }

  if (prefixPath.indexOf('products') >= 0) {
    return (
      <ProductGridWrapper mobile={mobile}>
        {products
          .filter(
            (product) =>
              !product.public_date ||
              new Date(product.public_date).getTime() < new Date().getTime(),
          )
          .sort((a, b) => {
            if (!a.priority) {
              return 1;
            }
            if (!b.priority) {
              return -1;
            }
            return a.priority - b.priority;
          })
          .map((product, i) => (
            <ProductItem
              key={product.id}
              mobile={mobile}
              product={product}
              onClick={(evt) => onItemClick(product, evt)}
            />
          ))}
        {new Array(products.length % 4).fill(0).map((item, key) => (
          <div className="filler" key={key} />
        ))}
      </ProductGridWrapper>
    );
  } else if (prefixPath.indexOf('articles') >= 0) {
    return (
      <ArticleGridWrapper>
        {products
          .sort((a, b) => {
            return (
              new Date(a.updated).getTime() - new Date(b.updated).getTime()
            );
          })
          .map((product, i) => (
            <ArticleItem key={i} item={product} />
          ))}
        {[0, 1, 2].map((_) => (
          <ArticleItem extraStyle="height: 0px;" />
        ))}
      </ArticleGridWrapper>
    );
  }
}

const ProductGridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.mobile ? 'center' : 'space-between')};
  padding: ${(props) => (props.mobile ? 0 : 'var(--basePadding)')};

  & > .filler {
    width: ${(props) => (props.mobile ? '160px' : '200px')};
    height: 1px;
    margin: 10px;
  }
`;

const ArticleGridWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media screen and (max-width: ${420 - 1}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export default ProductGrid;
