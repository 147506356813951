import React from 'react';
import styled, {keyframes} from 'styled-components';

function ProductItem(props) {
  const {product, onClick, mobile, empty} = props;
  const soldout =
    product.stock_type !== 'always' &&
    product.stock_sold_amount >= product.stock_amount;

  return (
    <ProductWrapper mobile={mobile} onClick={onClick} empty={empty}>
      {!empty && (
        <>
          <img
            src={
              (product.images &&
                product.images[0] &&
                product.images[0]['expected_url']) ||
              '../../images/empty-img.png'
            }
            alt="product"
          />

          <div className="info">
            <h3>{product.name}</h3>
            {soldout ? (
              <p style={{color: 'var(--primaryColor)'}}>已售完</p>
            ) : !!product.price ? (
              <p>NT {product.price}</p>
            ) : null}
          </div>
        </>
      )}
    </ProductWrapper>
  );
}

const ProductWrapper = styled.div`
  margin: 10px;
  width: ${(props) => (props.mobile ? '140px' : '180px')};
  height: ${(props) =>
    props.empty ? '0px' : props.mobile ? '270px' : '280px'};
  border-radius: 5px;
  overflow: hidden;
  /* position: relative; */
  cursor: pointer;
  transition: 300ms;
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.05); */

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.05);
  }

  & > .info {
    width: 100%;
    height: 40%;
    padding: 20px 10px;

    & > h3 {
      /* flex: 1; */
      text-align: center;
      font-weight: 300;
      font-size: 14px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: break-spaces;
    }

    & > p {
      text-align: center;
      font-weight: bold;
      letter-spacing: 1.29px;
    }
  }

  & > img {
    width: 100%;
    object-fit: contain;
    transition: 200ms;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export default ProductItem;
